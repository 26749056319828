import React, { useState } from 'react';
import SearchIcon from './img/search_icon_white.svg';
import AutocompleteWindow from './autocompleteWindow';
import router from 'next/router';
import { useCookies } from 'react-cookie';

const SearchBarDesktop = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [autocompleteData, setAutocompleteData] =
        useState<AutocompleteData | null>({
            products: [],
            textSuggestions: [],
            attributeSuggestions: { vendor: [], cat: [] }
        });
    const [cookies, setCookie, removeCookie] = useCookies(['search_history']);

    // navigate on submit
    const handleSubmit = (
        e: React.FormEvent<HTMLFormElement>,
        value: string
    ) => {
        e.preventDefault();

        router.push(`/suche?query=${value}`);

        if (cookies.search_history) {
            const searchHistoryList = cookies.search_history.split(',');

            if (searchHistoryList.length == 5) {
                searchHistoryList.shift();
                searchHistoryList.push(value);
            } else {
                !searchHistoryList.includes(value) &&
                    searchHistoryList.push(value);
            }

            setCookie('search_history', searchHistoryList.join(','), {
                path: '/',
                maxAge: 60 * 60 * 24 * 14 // lasts for 14 days
            });
        } else {
            setCookie('search_history', value, {
                path: '/',
                maxAge: 60 * 60 * 24 * 14
            });
        }

        handleClose();
    };

    const handleClose = () => {
        setIsOpen(false);
        document.body.classList.remove('disabled_scroll');
    };

    const inputText = () => {
        return router.query.query
            ? (router.query.query as string)
            : router.query.cat
            ? (router.query.cat as string)
            : 'Suchbegriff eingeben';
    };

    return (
        <div className="flex justify-center items-center w-full">
            <div
                className="flex cursor-pointer"
                role="presentation"
                onClick={() => {
                    setIsOpen(true);
                    document.body.classList.add('disabled_scroll');
                }}
            >
                <div className="flex items-center w-[500px] text-sm px-3.5 placeholder-current border border-solid border-skin-primary outline-none text-skin-muted xl:w-[600px]">
                    <span className="truncate w-full">{inputText()}</span>
                </div>
                <div className="px-3.5 py-2 border border-solid border-skin-red-accent bg-skin-red-accent">
                    <SearchIcon className="mt-[1px] h-5 w-6" />
                </div>
            </div>
            <AutocompleteWindow
                setAutocompleteData={setAutocompleteData}
                handleSubmit={handleSubmit}
                handleClose={handleClose}
                isOpen={isOpen}
                autocompleteData={autocompleteData}
                searchHistory={
                    cookies.search_history
                        ? cookies.search_history.split(',')
                        : null
                }
                setCookie={setCookie}
                removeCookie={removeCookie}
            />
        </div>
    );
};

interface AutocompleteData {
    products?: [];
    textSuggestions?: [];
    attributeSuggestions?: { vendor: []; cat: [] };
}

export default SearchBarDesktop;
