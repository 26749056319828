import React from 'react';
import DropshipTime from '../dropshipTime';
import { connect } from 'react-redux';
import convertToWeeks from '../../../../util/convertToWeeks';
import checkSpedition from '../../../../util/checkSpedition';

interface DeliveryTimePlainTypes {
    sku: string;
    deliveryType: number;
    selectedQuantity: number;
    deliveryInfo?: any;
    productMhsStock: string;
    deliveryTime: number;
    productState?: string;
}

const DeliveryTimePlain = (props: DeliveryTimePlainTypes) => {
    const {
        sku,
        deliveryType,
        deliveryTime,
        selectedQuantity,
        //deliveryInfo,
        productMhsStock
    } = props;

    const isSpedition = checkSpedition(deliveryType).isSpedition;

    // Add 2 days for package and 8 days for spedition
    const deliveryTimeAdjusted = isSpedition
        ? Number(deliveryTime) + 8
        : Number(deliveryTime) + 2;
    const deliveryTimeWeeks = convertToWeeks(deliveryTimeAdjusted);

    const handledMhsStorePacket = productMhsStock
        ? JSON.parse(productMhsStock).store_stock
        : null;
    // const handledZoneStock =
    //     productMhsStock != '' ? JSON.parse(productMhsStock).zone_stock : null;
    // const selectedMhsInfoDelivery = handledMhsStorePacket[deliveryInfo];

    const onlineStock =
        handledMhsStorePacket && handledMhsStorePacket['online'].stock
            ? handledMhsStorePacket['online'].stock
            : null;
    // const zoneStock = handledZoneStock[selectedMhsInfoDelivery?.zone]
    //     ? handledZoneStock[selectedMhsInfoDelivery.zone].stock
    //     : null;
    const dropshipingStock =
        handledMhsStorePacket && handledMhsStorePacket['dropshipping']
            ? handledMhsStorePacket['dropshipping'].stock
            : null;
    // console.log('Dropshipping: ', dropshipingStock);
    // console.log('Shipping time: : ', deliveryTime);

    return (
        <div>
            <span className="font-semibold text-left">
                {onlineStock > 0 && onlineStock >= selectedQuantity ? (
                    isSpedition ? (
                        <span className="font-semibold text-skin-green">
                            Auf Lager – Innerhalb 2 Wochen
                        </span>
                    ) : (
                        <span className="font-semibold text-skin-green">
                            {deliveryType == 26045
                                ? 'Auf Lager – 2-4 Werktage' // Spergut
                                : 'Auf Lager – 2-3 Werktage'}
                        </span>
                    )
                ) : dropshipingStock > 0 &&
                  dropshipingStock >= selectedQuantity ? (
                    <span className="font-semibold text-skin-green">
                        <DropshipTime sku={sku} />
                    </span>
                ) : deliveryTime != null ? (
                    <span className="text-skin-orange">
                        {deliveryTimeAdjusted > 28
                            ? 'Bestellbar - Über 4 Wochen'
                            : 'Bestellbar - Innerhalb ' + deliveryTimeWeeks}
                    </span>
                ) : null}
            </span>
        </div>
    );
};

const mapStateToProps = ({ pickupAndDelivery }: { pickupAndDelivery: any }) => {
    return {
        deliveryInfo: pickupAndDelivery.deliveryInfo,
        pickupInfo: pickupAndDelivery.pickupInfo
    };
};

export default connect(mapStateToProps)(DeliveryTimePlain);
